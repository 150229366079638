import type { Route } from '@/pages/routes'
import { replaceParams } from '@/pages/routes'

export enum ChildRoute {
  Dashboard = '/',
  LessonId = '/lesson/:id',
  History = '/history',
  Lessons = '/lessons',
  Profile = '/profile',
}

export const routeKey = '/child'

const buildPath = (route: string) => `${routeKey}${route}`

export const childRoute: Route<typeof ChildRoute> = {
  dashboard: () => buildPath(ChildRoute.Dashboard),
  lessonId: (id) => buildPath(replaceParams(ChildRoute.LessonId, { id })),
  history: () => buildPath(ChildRoute.History),
  lessons: () => buildPath(ChildRoute.Lessons),
  profile: () => buildPath(ChildRoute.Profile),
}
