export type MediaPathFolderKey = keyof MediaPath['folder']

type BuildProps = {
  url: string | undefined
  folderKey?: keyof MediaPath['folder']
  baseKey?: keyof MediaPath['base']
  defaultUrl?: string
}

class MediaPath {
  private readonly base = {
    base: import.meta.env.VITE_MEDIA_BASE_URL,
    pdf: import.meta.env.VITE_MEDIA_PDF_URL,
  }

  private readonly folder = {
    userProfilePicture: '/user/profile_pic',
    lesson: '/lesson',
    pdf: '/mixed',
    lessonResources: '/lesson/resources',
    lessonIcons: '/lesson/icons',
    lessonPlanets: '/lesson/planets',
    course: '/course',
    adNotification: '/notifications',
    badge: '/badges',
  }

  build({ url, folderKey, baseKey = 'base', defaultUrl = '' }: BuildProps) {
    if (!url) {
      return defaultUrl
    }

    const folder = folderKey ? this.folder[folderKey] : ''

    return /https?:/.exec(url)
      ? url
      : url?.includes('profile_pic')
      ? `${this.base[baseKey]}${folder}/${url?.replace('profile_pic/', '')}`
      : `${this.base[baseKey]}${folder}/${url}`
  }
}

export const mediaPath = new MediaPath()
